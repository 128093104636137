import React from 'react';
import { Link } from 'gatsby';

import Radek from '../images/radek-small.png';
import Dorota from '../images/dorota-small.png';
import Paulina from '../images/paulina-small.png';
import Elwira from '../images/elwira-small.png';
import TitleHeader from '../components/TitleHeader';
import SiteData from './SiteData';
import { FormattedMessage } from 'react-intl';
// import Img from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill"


const dorota = {
	backgroundImage: `url(${Dorota})`
};
const radek = {
	backgroundImage: `url(${Radek})`
};
const paulina = {
	backgroundImage: `url(${Paulina})`
};
const elwira = {
	backgroundImage: `url(${Elwira})`
};

const TemplateLink = ({ img, currentLang, name, link }) => {
	return (
		<Link
			className="img border-img"
			to={`${currentLang}/zespol/${link}`}
		>

			<Img
				className="section__image lazyload"
				fluid={img}
				objectFit="contain"
				style={{ width: "80%", height: "100%" }}
				alt={name}
			/>
			<div className="name-person-team">{name}</div>
		</Link>
	)
}

const Team = (props) => {
	const { languages, currentLang } = SiteData(props.location);
	console.log('Team', props.data);
	console.log('Team', currentLang);
	console.log('Team', languages);
	return (
		<div>
			{/* <TitleHeader title="Zespół" /> */}
			<TitleHeader title={<FormattedMessage id="menu.team" />} />

			<div className="content">
				<div className="choose-service">

					<TemplateLink
						img={props.data.radek.childImageSharp.fluid}
						currentLang={currentLang}
						name="dr n. med. Radosław Łysek"
						link="radoslaw-lysek"
					/>
					<TemplateLink
						img={props.data.paulina.childImageSharp.fluid}
						currentLang={currentLang}
						name="Lek. dent. Paulina Gęsich"
						link="paulina-gesich"
					/>
					<TemplateLink
						img={props.data.karolina.childImageSharp.fluid}
						currentLang={currentLang}
						name="Lek. dent. Karolina Krempa"
						link="karolina-krempa"
					/>
					<TemplateLink
						img={props.data.dorota.childImageSharp.fluid}
						currentLang={currentLang}
						name="Dorota Maliszewska"
						link="dorota-maliszewska"
					/>
					<TemplateLink
						img={props.data.monika.childImageSharp.fluid}
						currentLang={currentLang}
						name="Monika Bochenek"
						link="monika-bochenek"
					/>

					{/* 
					<Link to={`${currentLang}/zespol/radoslaw-lysek`} className="bg-img border-img" style={radek}>
						<div className="name-person-team">dr n. med. Radosław Łysek</div>
					</Link>
					<Link to={`${currentLang}/zespol/paulina-gesich`} className="bg-img border-img" style={paulina}>
						<div className="name-person-team">Lek. dent. Paulina Gęsich</div>
					</Link>
					<Link to={`${currentLang}/zespol/dorota-maliszewska`} className="bg-img border-img" style={dorota}>
						<div className="name-person-team">Dorota Maliszewska</div>
					</Link>
					<Link to={`${currentLang}/zespol/elwira-zawislak`} className="bg-img border-img" style={elwira}>
						<div className="name-person-team">Elwira Zawiślak</div>
					</Link> */}
				</div>
			</div>
		</div>
	);
};
export default Team;
// export const query = graphql`
// 	query {
// 		file(relativePath: { eq: "dorota.png" }) {
// 			childImageSharp {
// 				# Specify the image processing specifications right in the query.
// 				# Makes it trivial to update as your page's design changes.
// 				fluid {
// 					...GatsbyImageSharpFluid
// 				}
// 			}
// 		}
// 	}
// `;
